<template>
  <div class="plant-base">
    <x-table
      :no-data-text="CA('plantBase_check') ? '暂无数据' : '暂无数据查询权限'"
      :columns="table.columns"
      :data="table.data"
      :loading="table.loading"
      :config="config"
      @add="add"
      @search="search"
      @page-change="pageChange"
      @page-size-change="pageSizeChange"
      :showSummary="true"
      :summaryMethod="handleSummary"
    ></x-table>

    <Modal
      v-model="modal.show"
      :title="modal.title"
      @on-visible-change="modalShow"
    >
      <Form ref="form" :model="form" :rules="rules" :label-width="110">
        <FormItem label="种植基地名称" prop="name">
          <Input clearable v-model="form.name"></Input>
        </FormItem>
        <FormItem label="种植基地面积" prop="area">
          <Input v-model="form.area">
            <span slot="append">㎡</span>
          </Input>
        </FormItem>
        <FormItem label="负责人" prop="chargePerson">
          <Input clearable v-model="form.chargePerson"></Input>
        </FormItem>
        <FormItem label="负责人电话" prop="chargePersonTel">
          <Input clearable v-model="form.chargePersonTel"></Input>
        </FormItem>
        <FormItem label="详细地址" prop="address">
          <Input clearable v-model="form.address"></Input>
        </FormItem>
        <FormItem label="区域" prop="region">
          <!-- <Select clearable v-model="form.regionCode" placeholder="请选择区域">
            <Option :value="item.code" v-for="(item, index) in areaList">{{
              item.name
            }}</Option>
          </Select> -->
          <!-- <Input
            v-model="form.regionName"
            readonly
            @on-focus="
              () => {
                chooseArea.show = true;
              }
            "
          ></Input> -->
          <Cascader
            :data="areaList"
            v-model="form.region"
            change-on-select
            :load-data="loadData"
            placeholder="请选择区域"
          ></Cascader>
        </FormItem>
        <FormItem label="种植基地绘制" prop="mapPosition">
          <Input v-model="form.mapPosition" disabled>
            <span
              slot="append"
              class="iconfont"
              style="cursor: pointer"
              @click="drawMap"
              >&#xe648;</span
            >
          </Input>
        </FormItem>
        <FormItem label="上传图片">
          <upload-image
            v-model="form.imgUrl"
            :max="1"
            :clear-flag="!modal.show"
          ></upload-image>
        </FormItem>
      </Form>
      <p slot="footer" style="align-right: center">
        <Button type="primary" :loading="modal.submitLoading" @click="submit"
          >提交</Button
        >
        <Button @click="() => (modal.show = false)">取消</Button>
      </p>
    </Modal>

    <draw-map-modal
      v-model="drawMapModalShow"
      @draw-finish="drawFinish"
      :mapPosition="form.mapPosition"
    ></draw-map-modal>
    <!-- <area-modal
      v-model="chooseArea.show"
      @on-change="getArea"
      :areaIds="areaIds"
    ></area-modal> -->
    <!-- 地块弹窗 -->
    <block-modal v-model="BlockModalData.show" :farm="BlockModalData.farm">
    </block-modal>

    <Modal
      v-model="editorModal"
      title="基地信息配置"
      :width="900"
      @on-visible-change="editorModalShow"
    >
      <editor
        ref="detail"
        @editorContent="getEditorContent"
        v-model="remark"
        v-if="editorShow"
      ></editor>
      <p slot="footer" style="text-align: center">
        <Button type="primary" :loading="editorLoading" @click="infoSubmit"
          >提交</Button
        >
        <Button @click="() => (editorModal = false)">取消</Button>
      </p>
    </Modal>

    <picture-view v-model="picture.show" :src="picture.src"></picture-view>
  </div>
</template>

<script>
import areaModal from "@/components/areaModal";
import drawMapModal from "./drawMapModal";
import CU from "@/common/util";
import BlockModal from "../plantArea";
import editor from "@/components/editor";
import pictureView from "@/components/picture_view";

export default {
  name: "",
  components: {
    drawMapModal,
    areaModal,
    BlockModal,
    editor,
    pictureView,
  },
  data() {
    return {
      table: {
        columns: [
          {
            title: "种植基地图片",
            width: 120,
            align: "center",
            key: "",
            render: (h, { row }) => {
              if (!row.imgUrl) return <span>--</span>;
              return (
                <img
                  src={this.getImgUrl(row.servicePath, row.imgUrl)}
                  onClick={() => this.lookPicture(row.servicePath + row.imgUrl)}
                  style="width: 35px;height: 35px;padding-top:4px;cursor:pointer"
                />
              );
            },
          },
          {
            title: "种植基地名称",
            key: "name",
            minWidth: 160,
          },
          {
            title: "种植基地面积",
            width: 120,
            key: "area",
            render: (h, { row }) => {
              return <span>{row.area + "㎡"}</span>;
            },
          },
          {
            title: "负责人",
            width: 150,
            key: "chargePerson",
          },
          {
            title: "负责人电话",
            width: 150,
            key: "chargePersonTel",
          },
          {
            title: "种植基地地址",
            width: 260,
            key: "address",
          },
          {
            title: "创建时间",
            width: 180,
            key: "createTime",
          },
          {
            title: "操作",
            width: 240,
            render: (h, { row }) => {
              return (
                <div>
                  <a
                    style="margin-right:10px"
                    onClick={() => this.infoConfig(row)}
                  >
                    基地信息
                  </a>
                  {this.CA("plantBase_edit") && (
                    <a
                      style="margin-right: 10px"
                      onClick={() => this.edit(row)}
                    >
                      编辑
                    </a>
                  )}
                  {this.CA("plantArea_check") && (
                    <a
                      style="margin-right: 10px"
                      onClick={() => this.block(row)}
                    >
                      地块
                    </a>
                  )}
                  {this.CA("plantBase_del") && (
                    <Poptip
                      confirm
                      transfer
                      title="确定删除吗?"
                      on-on-ok={() => this.del(row.id)}
                    >
                      <a>删除</a>
                    </Poptip>
                  )}
                </div>
              );
            },
          },
        ],
        data: [],
        loading: false,
      },
      page: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
      },

      modal: {
        show: false,
        title: "",
        submitLoading: false,
      },

      form: {
        id: "",
        name: "",
        area: "",
        chargePerson: "",
        chargePersonTel: "",
        imgUrl: "",
        address: "",
        siteType: "1",
        mapPosition: "",
        thirdLongLat: "",
        //区域id
        regionCode: "",
        //区域名称
        regionName: "",
        region: [],
      },
      //已选择的区域id和名称列表
      regionCode: [],
      regionName: [],
      rules: {
        name: [{ required: true, message: "请填写种植基地名称" }],
        area: [{ required: true, message: "请填写种植面积" }],
        chargePerson: [{ required: true, message: "请填写负责人" }],
        chargePersonTel: [
          { required: true, message: "请填写联系电话" },
          {
            validator(rule, value, callback) {
              if (CU.validatePhone(value)) {
                callback();
                return;
              }
              if (CU.validateLandline(value)) {
                callback();
                return;
              }
              callback(rule.message);
            },
            message: "手机号格式不正确",
            trigger: "change",
          },
        ],
        address: [{ required: true, message: "请填写种植地址" }],
        mapPosition: [{ required: false, message: "请绘制基地地图" }],
        region: [{ required: true, message: "请选择区域" }],
      },
      drawMapModalShow: false,
      search_data: {},
      //区域列表接口
      areaList: [],

      //区域选择弹窗
      chooseArea: {
        show: false,
      },
      //区域id
      areaIds: "",

      // 地块弹窗数据
      BlockModalData: {
        show: false,
        farm: null,
      },
      editorModal: false,
      editorShow: false,
      remark: "",
      farmId: "",
      editorLoading: false,

      picture: {
        show: false,
        src: "",
      },
    };
  },
  computed: {
    config() {
      let config = {
        filter: {
          add: {
            addBtnName: "新增种植基地",
            ca: "plantBase_add",
          },
          width: 220,
          filterBox: [
            {
              conditionName: "种植基地名称",
              component: "input",
              field: "name",
              defaultValue: "",
            },
            {
              conditionName: "负责人",
              component: "input",
              field: "chargePerson",
              defaultValue: "",
            },
            {
              conditionName: "联系电话",
              component: "input",
              field: "chargePersonTel",
              defaultValue: "",
            },
            {
              conditionName: "基地地址",
              component: "input",
              field: "address",
              defaultValue: "",
            },
          ],
        },
        page: { ...this.page },
      };
      return config;
    },
  },
  methods: {
    // 打开地块弹窗
    block(row) {
      this.BlockModalData.farm = { ...row };
      this.BlockModalData.show = true;
    },

    pageChange(pageNo) {
      this.page.pageNo = pageNo;
      this.getList();
    },
    pageSizeChange(pageSize) {
      this.page.pageSize = pageSize;
      this.getList();
    },

    search(data) {
      this.search_data = data;
      this.page.pageNo = 1;
      this.getList();
    },

    add() {
      this.areaIds = "";
      this.modal = {
        show: true,
        title: "新增种植基地",
        submitLoading: false,
      };
    },
    drawMap() {
      this.drawMapModalShow = true;
    },
    drawFinish(data) {
      this.form.mapPosition = JSON.stringify(data);
      let center = data.length > 0 ? JSON.parse(data[0].options.center) : {};
      this.form.thirdLongLat = center.lng + "," + center.lat;
      this.drawMapModalShow = false;
    },

    getList() {
      if (!this.CA("plantBase_check")) return;
      this.table.loading = true;
      this.$post(this.$api.BASE_MANAGE.LIST, {
        pageNo: this.page.pageNo,
        pageSize: this.page.pageSize,
        siteType: "1",
        ...this.search_data,
      })
        .then((res) => {
          this.table.data = res.list;
          this.page.total = +res.total;
        })
        .finally(() => {
          this.table.loading = false;
        });
    },
    edit(row) {
      this.areaIds = row.regionCode;
      for (let key in this.form) {
        this.form[key] = row[key];
      }
      this.form.imgUrl = this.getImgUrl(row.servicePath, row.imgUrl);
      this.form.region = row.regionCode.split(",");
      this.modal = {
        show: true,
        title: "编辑种植基地",
        submitLoading: false,
      };
    },
    infoConfig(row) {
      this.farmId = row.id;
      this.remark = row.remark;
      this.editorModal = true;
      this.editorShow = true;
    },
    getEditorContent(content) {
      this.remark = content;
    },
    editorModalShow(visible) {
      if (visible) return;
      this.$refs.detail.editor.txt.html("");
      this.editorShow = false;
    },

    infoSubmit() {
      this.editorLoading = true;
      this.$post(this.$api.BASE_MANAGE.EDIT, {
        id: this.farmId,
        remark: this.remark,
      })
        .then(() => {
          this.getList();
          this.editorModal = false;
        })
        .finally(() => {
          this.editorLoading = false;
        });
    },
    del(id) {
      this.$post(this.$api.BASE_MANAGE.DELETE, {
        id,
      }).then(() => {
        this.$Message.success("删除成功");
        this.getList();
      });
    },

    //获取区域id
    // getArea(value) {
    //   this.regionCode = [];
    //   this.regionName = [];
    //   this.chooseArea.show = false;
    //   if (value.ids.length > 0) {
    //     value.ids.forEach((item) => {
    //       this.regionCode.push(item);
    //     });
    //     value.names.forEach((item) => {
    //       this.regionName.push(item);
    //     });
    //   }
    //   this.form.regionCode = this.regionCode.toString();
    //   this.form.regionName = this.regionName.toString();
    // },

    handleSummary({ columns, data }) {
      const sums = {};
      columns.forEach((column, index) => {
        const key = column.key;
        if (index === 0) {
          sums[key] = {
            key,
            value: "统计",
          };
          return;
        }
        const values = data.map((item) => Number(item[key]));
        if (column.key == "area") {
          if (!values.every((value) => isNaN(value))) {
            const v = values.reduce((prev, curr) => {
              const value = Number(curr);
              if (!isNaN(value)) {
                return prev + curr;
              } else {
                return prev;
              }
            }, 0);
            sums[key] = {
              key,
              value: v,
            };
          } else {
            sums[key] = {
              key,
              value: "",
            };
          }
        } else {
          sums[key] = {
            key,
            value: "",
          };
        }
      });
      return sums;
    },

    //获取区域列表
    getAreaList() {
      this.$post(this.$api.AREA_MANAGE.LIST, {
        parentId: "100000",
      }).then((res) => {
        res.forEach((item) => {
          item.label = item.name;
          item.value = item.id;
          item.loading = false;
          item.children = [];
        });
        this.areaList = res;
      });
    },

    loadData(item, callback) {
      item.loading = true;
      this.$post(this.$api.AREA_MANAGE.LIST, {
        parentId: item.id,
      })
        .then((res) => {
          res.forEach((item) => {
            item.label = item.name;
            item.value = item.id;
            if (item.hasChild == "true") {
              item.loading = false;
              item.children = [];
            }
          });
          item.children = res;
          callback();
        })
        .finally(() => {
          item.loading = false;
        });
    },

    modalShow(visible) {
      if (visible) return;
      this.form = {
        id: "",
        name: "",
        area: "",
        chargePerson: "",
        chargePersonTel: "",
        imgUrl: "",
        address: "",
        siteType: "1",
        mapPosition: "",
        thirdLongLat: "",
        regionCode: "",
        regionName: "",
        region: [],
      };
      this.$refs.form.resetFields();
    },
    submit() {
      this.$refs.form.validate().then((res) => {
        if (!res) return;
        let params = { ...this.form };
        this.form.imgUrl &&
          (params.imgUrl = "image" + this.form.imgUrl.split("image")[1]);
        this.modal.submitLoading = true;
        params.regionCode = this.form.region.join(",");
        delete params.region;
        this.$post(
          params.id ? this.$api.BASE_MANAGE.UPDATE : this.$api.BASE_MANAGE.ADD,
          params
        )
          .then(() => {
            this.getList();
            this.modal.show = false;
          })
          .finally(() => {
            this.modal.submitLoading = false;
          });
      });
    },
    addDeal(row) {
      this.dealForm.farmId = row.id;
      row.useType && (this.dealForm.useType = row.useType.split(","));
      this.dealModal = true;
    },

    lookPicture(url) {
      this.picture = {
        show: true,
        src: url,
      };
    },
  },
  mounted() {
    this.getList();
    this.getAreaList();
  },
};
</script>

<style lang="less" scoped>
.plant-base {
  width: 100%;
  height: 100%;
}
</style>
