<template>
  <Modal
    :value="value"
    title="区域选择"
    @input="(val) => $emit('input', val)"
    :width="500"
    @on-visible-change="modalShow"
  >
    <Tree :data="areaList" show-checkbox multiple ref="tree"></Tree>
    <p slot="footer" style="text-align: center">
      <Button type="primary" @click="submit">提交</Button>
      <Button @click="() => $emit('input', false)">取消</Button>
    </p>
  </Modal>
</template>

<script>
import CU from "@/common/util";
export default {
  name: "",
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    areaIds: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      //区域列表
      areaList: [],
      areaInfo: {
        //要提交的区域id
        ids: [],
        //要提交的区域名
        names: [],
      },
      selected: new Set(),
    };
  },
  methods: {
    //获取区域列表
    getAreaList() {
      this.$post(this.$api.AREA_MANAGE.LIST, {
        parentId: 100000,
      }).then((res) => {
        this.areaList = res.map((item) => {
          item.title = item.name;
          if (item.hasChild == 'true') {
            item.children = [];
            item.loading = false;
          }
          return item
        });
      });
    },
    //回显
    getAreaIds(val) {
      val.forEach((item) => {
        if (this.selected.has(item.id)) {
          item.checked = true;
        }
        if (item.children && item.children.length > 0) {
          this.getAreaIds(item.children);
        }
      });
    },
    //提交区域
    submit() {
      this.areaInfo.ids = [];
      this.areaInfo.names = [];
      let items = this.$refs.tree.getCheckedNodes();
      if (items.length > 0) {
        items.forEach((item) => {
          this.areaInfo.ids.push(item.id);
          this.areaInfo.names.push(item.title);
        });
      }
      this.$emit("on-change", this.areaInfo);
    },
    modalShow(visible) {
      if (visible) {
        if (this.areaIds) {
          this.areaIds.split(",").forEach((item) => {
            this.selected.add(item);
          });
        }
        this.getAreaList();
      } else {
        this.areaInfo = {
          ids: [],
          names: [],
        };
        this.areaList = [];
        this.selected.clear();
      }
    },
  },
};
</script>

<style lang="less" scoped>
</style>